const tools = [
  {
    id: 1,
    href: "#tool1",
    image: "./assets/ales-nesetril-Im7lZjxeLhg-unsplash.jpg",
    title: "SMIL File Visualiser",
    description:
      "A visualiser for Simplestream SMIL files to identify Video and Ad segments",
    showArrow: true,
    span: 1,
    isLocked: false,
  },
  {
    id: 2,
    href: "#tool2",
    image: "./assets/alexander-shatov-DHl49oyrn7Y-unsplash.jpg",
    title: "HLS Tag Finder",
    description:
      "Not sure what you're trying to find in an HLS feed? Remove what you do know and just leave the rest",
    showArrow: true,
    span: 1,
    isLocked: false,
  },
  {
    id: 3,
    href: "#tool3",
    image: "./assets/henry-chen-XxVQlk2QILE-unsplash.jpg",
    title: "Hybrid TV URL builder",
    description:
      "Remembering all of the Simplestream Hybrid URLs can be difficult, just fill out this form to get yours instead",
    badge: {
      text: "In Development",
      color: "green",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 4,
    href: "#tool4",
    image: "./assets/pavel-moiseev-hFmxJMnvECc-unsplash.jpg",
    title: "Tool 4",
    description: "Description of Tool 4",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 5,
    href: "#tool5",
    image: "./assets/ryunosuke-kikuno-L7ij5IFCYqA-unsplash.jpg",
    title: "Tool 5",
    description: "Description of Tool 5",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 2,
    isLocked: true,
  },
  {
    id: 6,
    href: "#advert1",
    image: "./assets/Advert-Pictures-Download-Free-Images-on-Unsplash.png",
    title: "Advert",
    adClient: "ca-pub-8606875656436019",
    adSlot: "6144893176",
    isAd: true, // This is an ad
    span: 2, // Span across 2 columns
  },
  {
    id: 7,
    href: "#tool7",
    image: "./assets/samsung-memory-cNcMgLCY-wE-unsplash.jpg",
    title: "Tool 6",
    description: "Description of Tool 6",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 8,
    href: "#tool8",
    image: "./assets/samsung-memory-rMSYJWOIgMw-unsplash.jpg",
    title: "Tool 7",
    description: "Description of Tool 7",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 9,
    href: "#tool9",
    image: "./assets/samsung-memory-ujDTFimW2mE-unsplash.jpg",
    title: "Tool 8",
    description: "Description of Tool 8",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 10,
    href: "#tool10",
    image: "./assets/shubham-sharan-mMHtwKUGucQ-unsplash.jpg",
    title: "Tool 9",
    description: "Description of Tool 9",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 11,
    href: "#tool11",
    image: "./assets/ales-nesetril-Im7lZjxeLhg-unsplash.jpg",
    title: "Tool 10",
    description: "Description of Tool 10",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 12,
    href: "#tool12",
    image: "./assets/alexander-shatov-DHl49oyrn7Y-unsplash.jpg",
    title: "Tool 11",
    description: "Description of Tool 11",
    badge: {
      text: "Coming Soon",
      color: "orange",
    },
    span: 1,
    isLocked: true,
  },
  {
    id: 13,
    href: "#tool13",
    image: "./assets/jakub-dziubak-gj7BLlSzIFs-unsplash.jpg",
    title: "A Drink",
    description:
      "Every now and again. Not really a technical tool, but it helps",
    badge: {
      text: "Finished",
      color: "orange",
    },
    span: 1,
    isLocked: false,
  },
  {
    id: 14,
    href: "#advert2",
    image: "./assets/Advert-Pictures-Download-Free-Images-on-Unsplash.png",
    title: "Advert",
    adClient: "ca-pub-xxxxxxxxxxxxxxxx",
    adSlot: "1234567890",
    isAd: true, // This is an ad
    span: 2, // Span across 2 columns
  },
];

export default tools;
