import React from "react";

function Footer() {
  return (
    <footer className="bg-black dark:bg-gray-200 py-4 mt-10">
      <div className="container mx-auto text-center text-gray-200/20 dark:text-gray-700/50 text-xs sm:text-sm">
        <p>
          &copy; 2024
          <a
            href="https://www.linkedin.com/in/jaredpestugia/"
            className="hover:text-gray-400 dark:hover:text-gray-800 transition-200 ml-1"
          >
            Jared Pestugia
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
