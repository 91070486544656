import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";
import MenuBar from "./components/MenuBar";
import BlogList from "./blog/BlogList"; // Import the BlogList component
import BlogPost from "./blog/BlogPost"; // Import the BlogPost component
import tools from "./data/toolsData";

import "./styles.css";

function App() {
  return (
    <Router>
      <div className="bg-black dark:bg-gray-200 text-white dark:text-black">
        <Header />
        <MenuBar />
        <Routes>
          <Route path="/" element={<MainContent tools={tools} />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
