import React from "react";

const AdFallback = () => {
  return (
    <div className="bg-gray-800 text-white dark:text-black p-4 text-center rounded-md">
      <p>Ad failed to load. Please check back later.</p>
    </div>
  );
};

export default AdFallback;
