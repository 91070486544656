import React, { useState, useEffect } from "react";
import {
  BuildingStorefrontIcon,
  NewspaperIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";
import { MusicalNoteIcon, SunIcon } from "@heroicons/react/24/solid";

function MenuBar() {
  const [selectedIcon, setSelectedIcon] = useState("Home");
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    console.log("useEffect triggered on component mount");

    // Check the user's preference in localStorage
    const storedDarkMode = localStorage.getItem("darkMode") === "true";
    console.log(`Stored dark mode preference found: ${storedDarkMode}`);

    setDarkMode(storedDarkMode);

    // Apply the dark mode class if needed
    if (storedDarkMode) {
      console.log("Applying dark mode class to document");
      document.documentElement.classList.add("dark");
    } else {
      console.log("Removing dark mode class from document");
      document.documentElement.classList.remove("dark");
    }

    // Determine the initial selected icon based on the current path
    const path = window.location.pathname;
    console.log(`Current path: ${path}`);

    if (path === "/") {
      console.log("Setting selected icon to Home");
      setSelectedIcon("Home");
    } else if (path.startsWith("/blog")) {
      console.log("Setting selected icon to News");
      setSelectedIcon("News");
    } else if (path.startsWith("/music")) {
      console.log("Setting selected icon to Music");
      setSelectedIcon("Music");
    } else {
      console.log("No matching path for setting selected icon");
    }
  }, []);

  const handleMouseEnter = (iconName) => {
    setHoveredIcon(iconName);
    setTimeout(() => setHoveredIcon(null), 5000);
  };

  const handleIconClick = (iconName) => {
    setSelectedIcon(iconName);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode); // Save the preference to localStorage

    if (newDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 backdrop-blur-sm bg-black/10 text-white flex justify-around items-center w-56 py-2 border border-gray-200/10 rounded-md z-50">
      <a
        href="/"
        className="relative flex flex-col items-center p-1 rounded-lg hover:bg-white/10"
        onMouseEnter={() => handleMouseEnter("Home")}
        onClick={() => handleIconClick("Home")}
      >
        <BuildingStorefrontIcon
          className={`h-6 w-6 transition-transform duration-200 ${
            selectedIcon === "Home" ? "text-white" : "text-gray-400"
          } hover:scale-110 hover:text-orange-400`}
        />
        {hoveredIcon === "Home" && (
          <span className="absolute bottom-12 text-xs bg-black text-white py-1 px-2 rounded opacity-90">
            Home
          </span>
        )}
      </a>
      <a
        href="/blog"
        className="relative flex flex-col items-center p-1 rounded-lg hover:bg-white/10"
        onMouseEnter={() => handleMouseEnter("News")}
        onClick={() => handleIconClick("News")}
      >
        <NewspaperIcon
          className={`h-6 w-6 transition-transform duration-200 ${
            selectedIcon === "News" ? "text-white" : "text-gray-400"
          } hover:scale-110 hover:text-orange-400`}
        />
        {hoveredIcon === "News" && (
          <span className="absolute bottom-12 text-xs bg-black text-white py-1 px-2 rounded opacity-90">
            News
          </span>
        )}
      </a>
      <a
        href="https://open.spotify.com/playlist/61nCeLC8vcffJexz8f854H?si=dab61988e5bc48dc" // Replace with your Spotify playlist URL
        target="_blank"
        rel="noopener noreferrer"
        className="relative flex flex-col items-center p-1 rounded-lg hover:bg-white/10"
        onMouseEnter={() => handleMouseEnter("Music")}
        onClick={() => handleIconClick("Music")}
      >
        <MusicalNoteIcon
          className={`h-6 w-6 transition-transform duration-200 ${
            selectedIcon === "Music" ? "text-white" : "text-gray-400"
          } hover:scale-110 hover:text-orange-400`}
        />
        {hoveredIcon === "Music" && (
          <span className="absolute bottom-12 text-xs bg-black text-white py-1 px-2 rounded opacity-90">
            Music
          </span>
        )}
      </a>
      <div className="relative flex flex-col items-center">
        <button
          onClick={toggleDarkMode}
          className="relative flex flex-col items-center p-1 rounded-lg hover:bg-white/10"
          onMouseEnter={() => handleMouseEnter("DarkMode")}
        >
          {darkMode ? (
            <SunIcon
              className={`h-6 w-6 transition-transform duration-200 ${
                selectedIcon === "DarkMode" ? "text-white" : "text-gray-400"
              } hover:scale-110 hover:text-orange-400`}
            />
          ) : (
            <MoonIcon
              className={`h-6 w-6 transition-transform duration-200 ${
                selectedIcon === "DarkMode" ? "text-white" : "text-gray-400"
              } hover:scale-110 hover:text-orange-400`}
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default MenuBar;
