import React from "react";
import ToolCard from "./ToolCard";

function MainContent({ tools }) {
  return (
    <main className="container mx-auto mt-10">
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
        {tools.map((tool, index) => (
          <ToolCard key={tool.id} {...tool} delay={index * 0.1} />
        ))}
      </div>
    </main>
  );
}

export default MainContent;
