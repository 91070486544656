import React from "react";

function Header() {
  return (
    <header className="text-white dark:text-black dark:text-black py-6 sm:py-12">
      <div className="container mx-auto flex flex-col items-center text-center">
        <div className="flex items-center mb-4">
          <img
            src="/assets/logo-white-256x256.png"
            alt="Logo"
            className="h-16 mr-4"
          />
          <h1 className="sm:text-5xl text-2xl font-medium">
            Streaming Media Toolbox
          </h1>
        </div>
        <p className="mt-2 text-white dark:text-black/60 font-light">
          Tools to make small tasks in the streaming industry easier
        </p>
      </div>
    </header>
  );
}

export default Header;
