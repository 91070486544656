import React from "react";
import { Link } from "react-router-dom";
import blogData from "./blogData";

function BlogList() {
  return (
    <div className="blog-list-container max-w-md mx-auto p-2">
      <h1 className="text-2xl font-semibold mb-4 text-white dark:text-black">
        Blog
      </h1>
      <div className="space-y-1">
        {blogData.map((post) => (
          <Link
            key={post.id}
            to={`/blog/${post.id}`}
            className="block py-2 px-2 hover:bg-gray-900 dark:hover:bg-gray-300 transition-colors duration-200 rounded-md"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-base font-light text-gray-300 dark:text-gray-700">
                {post.title}
              </h2>
              <span className="text-xs text-gray-500 dark:text-gray-700">
                {post.date}
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BlogList;
