import React from "react";
import classNames from "classnames";
import GoogleAd from "./GoogleAd";

function ToolCard({
  href,
  image,
  title,
  description,
  badge,
  showArrow,
  span = 1,
  isAd = false,
  adClient,
  adSlot,
  delay,
  isLocked = false, // Prop to indicate if the card is locked
}) {
  const badgeClass = classNames({
    "bg-green-400/20 text-green-400 border-green-400/70":
      badge?.color === "green",
    "bg-orange-400/20 text-orange-400 border-orange-400/70":
      badge?.color === "orange",
  });

  const gridSpanClass = `lg:col-span-${span} sm:col-span-${Math.min(span, 2)}`;
  const animationClass = `animate-fadeInUp`;
  const delayStyle = { animationDelay: `${delay}s` };

  return (
    <a
      href={href}
      className={`block bg-black/70 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 ${
        !isAd ? "hover:scale-105" : ""
      } opacity-0 ${gridSpanClass} ${animationClass}`}
      style={delayStyle}
    >
      <div className={`relative ${!isAd ? "group" : ""}`}>
        {isAd ? (
          <GoogleAd adClient={adClient} adSlot={adSlot} />
        ) : (
          <>
            <img
              src={image}
              alt={title}
              className={`w-full h-72 sm:h-96 object-cover transition-transform duration-300 transform ${
                !isAd ? "group-hover:scale-105" : ""
              }`}
            />

            {/* Better padlock icon */}
            {isLocked && (
              <div className="absolute top-0 left-0 m-2 text-white bg-gray-200/10 p-1 rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  class="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              </div>
            )}

            {!isAd && badge && (
              <div className="absolute top-0 right-0 m-2">
                <span
                  className={`text-xs px-2 py-1 rounded-full mt-1 inline-block ${badgeClass}`}
                >
                  {badge.text}
                </span>
              </div>
            )}
            <div
              className={`absolute inset-0 ${
                !isAd
                  ? "bg-gradient-to-t from-black/80 via-transparent to-transparent"
                  : ""
              }`}
            >
              {showArrow && !isAd && (
                <div className="absolute top-0 right-0 m-2 text-white opacity-0 group-hover:opacity-100 transition-opacity">
                  <span className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="h-6 w-6"
                    >
                      <path d="M7 17L17 7M7 7h10v10"></path>
                    </svg>
                  </span>
                </div>
              )}
              <div className="absolute bottom-0 p-4">
                {!isAd && (
                  <h2 className="text-sm sm:text-lg font-semibold text-white">
                    {title}
                  </h2>
                )}
                {!isAd && (
                  <p className="mt-2 text-gray-400 text-xs">{description}</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </a>
  );
}

export default ToolCard;
