import React from "react";
import { useParams, Link } from "react-router-dom";
import blogData from "./blogData";
import { TracingBeam } from "../components/ui/tracerbeam";

function BlogPost() {
  const { id } = useParams();
  const post = blogData.find((post) => post.id === parseInt(id));

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div className="max-w-xl mx-auto p-4 text-gray-300 relative sm:max-w-2xl">
      <Link
        to="/blog"
        className="text-sm text-gray-500 hover:text-gray-400 transition-colors duration-200 flex items-center mb-4"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="h-4 w-4 mr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back to Blog
      </Link>

      <TracingBeam className="px-4 sm:px-6">
        {post.image && (
          <img
            src={post.image}
            alt={post.title}
            className="rounded-lg mb-4 object-contain pl-10"
          />
        )}

        {post.badge && (
          <h2 className="bg-black dark:bg-gray-400/20 text-white dark:text-black rounded-full border border-white/60 dark:border-black/60 text-sm w-fit px-2 mb-2 mx-10">
            {post.badge}
          </h2>
        )}

        <h1 className="text-xl sm:text-2xl font-light mb-4 text-white dark:text-black leading-tight pl-10">
          {post.title}
        </h1>

        <div className="content mt-4 text-gray-300 dark:text-gray-700 prose prose-sm dark:prose-invert max-w-none pl-10">
          {/* Render the post content */}
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
      </TracingBeam>
    </div>
  );
}

export default BlogPost;
