import React, { useEffect, useState } from "react";
import AdFallback from "./adFallback"; // Import the fallback component

const GoogleAd = ({ adClient, adSlot }) => {
  const [adFailed, setAdFailed] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAdFailed(true);
    }, 5000); // Wait 5 seconds before showing fallback

    const adScript = document.createElement("script");
    adScript.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    adScript.async = true;
    adScript.crossOrigin = "anonymous";
    adScript.onload = () => clearTimeout(timeout); // Clear timeout if ad loads
    adScript.onerror = () => setAdFailed(true); // Trigger fallback if ad fails
    document.body.appendChild(adScript);

    return () => {
      clearTimeout(timeout);
      document.body.removeChild(adScript);
    };
  }, []);

  if (adFailed) {
    return <AdFallback />;
  }

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center" }}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client={adClient}
      data-ad-slot={adSlot}
    ></ins>
  );
};

export default GoogleAd;
